import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63')
];

export const server_loads = [0,2,3,4];

export const dictionary = {
		"/": [~11],
		"/about-us": [~12],
		"/auth/account": [13,[2]],
		"/auth/account/courses": [14,[2]],
		"/auth/account/courses/[id]": [15,[3]],
		"/auth/account/courses/[id]/[section]": [~16,[3]],
		"/auth/account/courses/[id]/[section]/[lesson]": [~17,[3]],
		"/auth/account/membership": [~18,[2]],
		"/auth/account/orders": [~19,[2]],
		"/auth/account/tutorials": [~20,[2]],
		"/auth/account/user-info": [~21,[2]],
		"/auth/admin": [22,[4]],
		"/auth/admin/courses": [23,[4]],
		"/auth/admin/courses/[id]": [~24,[4]],
		"/auth/admin/members": [~25,[4]],
		"/auth/admin/members/[id]": [~26,[4]],
		"/auth/admin/products": [~27,[4]],
		"/auth/admin/tutorials": [28,[4]],
		"/auth/admin/tutorials/[id]": [~29,[4]],
		"/auth/login": [~30],
		"/auth/logout": [~31],
		"/auth/register": [~32],
		"/blog": [~33],
		"/blog/[slug]": [~34],
		"/collections": [~35,[5],[6]],
		"/collections/bundles": [~38,[5],[6]],
		"/collections/bundles/[handle]": [~39,[5,8],[6]],
		"/collections/courses": [~40,[5],[6]],
		"/collections/courses/advanced-bare-microblading": [~41,[5],[6]],
		"/collections/courses/building-a-brow": [~42,[5],[6]],
		"/collections/courses/foundations-of-fluff": [~43,[5],[6]],
		"/collections/courses/lips-by-mikaila": [~44,[5],[6]],
		"/collections/courses/nano-machine-hair-strokes": [~45,[5],[6]],
		"/collections/trainings/[handle]": [~46,[5],[6]],
		"/collections/tutorials": [~47,[5,9],[6]],
		"/collections/tutorials/[handle]": [~48,[5,9],[6]],
		"/collections/[handle]": [~36,[5],[6]],
		"/collections/[handle]/[handle]": [~37,[5,7],[6]],
		"/contact": [~49],
		"/education": [50,[10]],
		"/education/membership": [~51,[10]],
		"/education/membership/join": [~52,[10]],
		"/education/membership/join/cancel": [53,[10]],
		"/education/membership/join/success": [~54,[10]],
		"/education/trainings": [~55,[10]],
		"/education/trainings/[handle]": [~56,[10]],
		"/faqs": [~57],
		"/faqs/client-faqs": [~58],
		"/faqs/product-faqs": [~59],
		"/landing": [~60],
		"/policy/return": [~61],
		"/policy/shipping": [~62],
		"/pro-team": [~63]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';